import React from 'react'
import styled from 'styled-components'
import { colors, GreyBtn, md } from '../common'
import { Link } from 'gatsby'

export default ({ data, title = `ENGAGEMENT RINGS`, link = '/' }) => {
  const items = data.postgres.allProductsItems.edges
  return(
    <div css={`padding:2rem;text-align:center;margin-bottom:1rem;`}>
    <h1 css={`color:${colors.green};margin:0;`}>{title}</h1>
    <Wrap>
      {items.map((item, index) => {
        const i = item.node
        return  <Card key={index}>
                  <div css={`width:100%`}>
                    <img src={i.image} alt={i.title} />
                  </div>
                  <p css={`font-size:18px;color:${colors.greyDarker};`}>{i.title}</p>
                  <p css={`color:${colors.green};`}>{i.designer}</p>
                </Card>
      })}
    </Wrap>
    <Link to={link}><GreyBtn>View More {title}</GreyBtn></Link>
    </div>
  )
}

const Card = styled.div`
  width: calc(25% -1rem);
  margin: .5rem;
  text-align:center;

  img {
    width: 100%;
  }
`

const Wrap = styled.div`
  display: flex;

  ${md} {
    flex-direction:column;
  }
`

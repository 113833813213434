import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import { PageHead } from '../common'
import Products from '../sections/content-products'

export default ({ data }) => (
  <Layout>
    <SEO title="UNderwood's Exclusive Designs" />
    <PageHead>
      <h1>Underwood's Exclusive Designs</h1>
      <p>Underwood’s is proud to present some of the most spectacular diamond engagement, colored stone and anniversary rings you will see anywhere. Rare and exquisite, every item is a one of a kind, featuring the finest quality diamonds meticulously set in handcrafted platinum or 18 karat gold. Each diamond is hand selected by one of our educated buyers and most designs are created by the company’s President, Clayton Bromberg. These are the finest pieces of jewelry you will find in Jacksonville and Ponte Vedra Beach, FL.</p>
    </PageHead>
    <Products data={data} title={``} link='/products/underwoods-designs'/>
    <JoinUs />
  </Layout>
)

export const UnderwoodsQuery = graphql`
  query UnderwoodsPageQuery {
  postgres {
    allProductsItems(first: 3, orderBy: ID_DESC, condition: {designer: "Underwood's Designs"}) {
      edges {
        node {
          title
          designer
          image
        }
      }
    }
  }
}
`
